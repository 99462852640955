"use client";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import * as yup from "yup"; // LOCAL CUSTOM COMPONENTS

import EyeToggleButton from "../components/eye-toggle-button"; // LOCAL CUSTOM HOOK

import BoxLink from "../components/box-link";
import usePasswordVisible from "../use-password-visible"; // GLOBAL CUSTOM COMPONENTS

import { Span } from "components/Typography";
import { FlexBox } from "components/flex-box";
import BazaarTextField from "components/BazaarTextField";
import { useState } from "react";
import { FlexRowCenter } from "components/flex-box";
import { useRouter } from "next/navigation";
import CircularProgress from "@mui/material/CircularProgress";

const RegisterPageView = () => {
  const [userMessage, setUserMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { push } = useRouter();

  const { visiblePassword, togglePasswordVisible } = usePasswordVisible(); 

  const handleLoginClick = () => {
    setLoading(true)
    push('/login')
  }

  const inputProps = {
    endAdornment: (
      <EyeToggleButton show={visiblePassword} click={togglePasswordVisible} />
    ),
  }; // REGISTER FORM FIELDS INITIAL VALUES

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
    agreement: true,
  }; // REGISTER FORM FIELD VALIDATION SCHEMA

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup.string().email("invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
    re_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Please re-type password"),
    agreement: yup
      .bool()
      .test(
        "agreement",
        "Please read and agree with our Terms and Conditions to proceed",
        (value) => value === true
      )
      .required(
        "Please read and agree with our Terms and Conditions to proceed"
      ),
  });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values, { resetForm }) => {
        const formValues = values;
        resetForm();
        setUserMessage("");
        setLoading(true);

        try {
          const response = await fetch("/api/customer/signup", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
          });
          if (!response.ok) {
            setUserMessage("An account already exists with that E-mail");
          }

          const data = await response.json();

          // ON SUCCESS
          if (!data.status) {
            push("/login");
            resetForm();
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  return (
    <form onSubmit={handleSubmit}>
      <FlexRowCenter>
        {loading && <CircularProgress color="primary" />}{" "}
        <p style={{ color: "red" }}>{userMessage}</p>
      </FlexRowCenter>

      <BazaarTextField
        mb={1.5}
        fullWidth
        name="first_name"
        size="small"
        label="First Name"
        variant="outlined"
        onBlur={handleBlur}
        value={values.first_name}
        onChange={handleChange}
        placeholder=""
        error={!!touched.first_name && !!errors.first_name}
        helperText={touched.first_name && errors.first_name}
      />

      <BazaarTextField
        mb={1.5}
        fullWidth
        name="last_name"
        size="small"
        label="Last Name"
        variant="outlined"
        onBlur={handleBlur}
        value={values.last_name}
        onChange={handleChange}
        placeholder=""
        error={!!touched.last_name && !!errors.last_name}
        helperText={touched.last_name && errors.last_name}
      />

      <BazaarTextField
        mb={1.5}
        fullWidth
        name="email"
        size="small"
        type="email"
        variant="outlined"
        onBlur={handleBlur}
        value={values.email}
        onChange={handleChange}
        label="Email"
        placeholder=""
        error={!!touched.email && !!errors.email}
        helperText={touched.email && errors.email}
      />

      <BazaarTextField
        mb={1.5}
        fullWidth
        size="small"
        name="password"
        label="Password"
        variant="outlined"
        autoComplete="on"
        placeholder="*********"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.password}
        type={visiblePassword ? "text" : "password"}
        error={!!touched.password && !!errors.password}
        helperText={touched.password && errors.password}
        InputProps={inputProps}
      />

      <BazaarTextField
        fullWidth
        size="small"
        autoComplete="on"
        name="re_password"
        variant="outlined"
        label="Retype Password"
        placeholder="*********"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.re_password}
        type={visiblePassword ? "text" : "password"}
        error={!!touched.re_password && !!errors.re_password}
        helperText={touched.re_password && errors.re_password}
        InputProps={inputProps}
      />

      <FormControlLabel
        name="agreement"
        className="agreement"
        onChange={handleChange}
        control={
          <Checkbox
            size="small"
            color="secondary"
            checked={values.agreement || false}
          />
        }
        label={
          <FlexBox
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
            gap={1}
          >
            <Span
              display={{
                sm: "inline-block",
                xs: "none",
              }}
            >
              By signing up, you agree to
            </Span>
            <Span
              display={{
                sm: "none",
                xs: "inline-block",
              }}
            >
              Accept Our
            </Span>
            <BoxLink title="Terms & Conditions" href="/info/terms" />
          </FlexBox>
        }
      />

      <Button
        fullWidth
        type="submit"
        color="primary"
        variant="contained"
        size="large"
      >
        Create Account
      </Button>

      <FlexRowCenter>
        <Button
                onClick={handleLoginClick}
                fontWeight={600}
                bordercolor="grey.900"
              >
                Already have an account?
              </Button>
      </FlexRowCenter>
    </form>
  );
};

export default RegisterPageView;
