"use client";

import { Fragment } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup"; // LOCAL CUSTOM COMPONENT

import BoxLink from "../components/box-link"; // GLOBAL CUSTOM COMPONENTS
import CircularProgress from "@mui/material/CircularProgress";

import { H3 } from "components/Typography";
import { FlexRowCenter } from "components/flex-box";
import Loading from '../../../app/loading'
import {useState} from 'react';
import {useRouter} from 'next/navigation'

const ResetPassword = () => {
  const [userMessage, setUserMessage] = useState("Reset your password");
  const [loading, setLoading] = useState(false);
  const {push} = useRouter();

  const initialValues = {
    email: ""
  }; 

  const validationSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("Email is required")
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formValues = values;
      resetForm();
      setUserMessage("");
      setLoading(true);

        try {
          const response = await fetch("/api/customer/resetpw", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
          });

          const data = await response.json();

          // ON SUCCESS
          if (data.status === 200) {
            setUserMessage(data.message)

          } else {
            setUserMessage(data.message || "No account exists with that E-mail address");
          }
        } catch (error) {
          console.log("catch");
          console.error("Error submitting form:", error);
        } finally {
          setLoading(false);
        }


    }
  });
  return <Fragment>
      <H3 mb={3} textAlign="center">
{userMessage}      </H3>

      <FlexRowCenter style={{ position: "relative", top: "-50px"}}>
        {loading && <CircularProgress color="primary" />}{" "}
      </FlexRowCenter>
      {
      /* FORM AREA */
    }
      <Box onSubmit={handleSubmit} component="form" display="flex" flexDirection="column" gap={2}>
        <TextField fullWidth name="email" type="email" label="Email" onBlur={handleBlur} value={values.email} onChange={handleChange} helperText={touched.email && errors.email} error={Boolean(touched.email && errors.email)} />

        <Button fullWidth type="submit" color="primary" variant="contained">
          Reset
        </Button>
      </Box>

      {
      /* BOTTOM LINK AREA */
    }
      <FlexRowCenter mt={3} justifyContent="center" gap={1}>
        Don&apos;t have an account?
        <BoxLink title="Register" href="/register" />
      </FlexRowCenter>

      
    </Fragment>;
};

export default ResetPassword;