import { useContext, useCallback } from "react";
import { CartContext } from "contexts/CartContext";
import CoCart from "../lib/coCartInstance";
import { debounce } from "lodash";

export function useCartActions() {
  const {
    cart,
    refreshCart,
    setCart,
    getCartKey,
    storeCartKey,
    getCredentials,
    getCartKeyUsedForCheckout,
    storeCartKeyUsedForCheckout
  } = useContext(CartContext);

  const updateCartState = useCallback(
    (updatedCart) => {
      setCart(updatedCart);
    },
    [setCart]
  );

  const addToCart = async (product, quantity) => {
    let cartKey = null;
    let credentials = null;
    cartKey = await getCartKey()
    credentials = await getCredentials();
    let url = ''

    const updatedCart = cart.find((item) => item.id === product.id)
      ? cart.map((item) =>
          item.id === product.id ? { ...item, qty: quantity } : item
        )
      : [...cart, { ...product, qty: quantity }];

    updateCartState(updatedCart);

    try {
      // Logged in, no key
      if (credentials !== null && cartKey === null) {
        // console.log('inUseCartActions: YES credentials, NO key')
        url = "cart/add-item";
        CoCart.consumerKey = credentials[0];
        CoCart.consumerSecret = credentials[1];
      }
      // Returning guest
      else if (credentials === null && cartKey !== null) {
        // console.log('inUseCartActions: NO credentials, YES key')
        url = `cart/add-item?cart_key=${cartKey}`
      }
      // Has guest cart but logged in
      else if (credentials !== null && cartKey !== null) {
        // console.log('inUseCartActions: HAS BOTH!!! NOOOO ')
        url = `cart/add-item?cart_key=${cartKey}`
        CoCart.consumerKey = credentials[0];
        CoCart.consumerSecret = credentials[1];
      }
      // Welcome first timer
      else if (credentials === null && cartKey === null) {
        // console.log('inUseCartActions: NO credentials, NO key')
        url = "cart/add-item";       
      }

      const response = await CoCart.post(url, {
        id: product.id.toString(),
        quantity: quantity.toString(),
      });
      // console.log('inUseCartActions: url ', url)

      // console.log(response.data.cart_key, '= user ID in woo')
      if (credentials !== null && cartKey !== null) {
        storeCartKeyUsedForCheckout(response.headers["cocart-api-cart-key"])
        localStorage.removeItem('boh_cart_key');
      }

      else if (cartKey === null && credentials === null && response.headers["cocart-api-cart-key"]) {
        storeCartKey(response.headers["cocart-api-cart-key"]);
        storeCartKeyUsedForCheckout(response.headers["cocart-api-cart-key"])

      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
    refreshCart();
  };

  const updateCart = useCallback(
    debounce(async (itemKey, quantity) => {
      const cartKey = getCartKey();
      const credentials = await getCredentials();

      if (credentials) {
        CoCart.consumerKey = credentials[0];
        CoCart.consumerSecret = credentials[1];
      }
      try {
        await CoCart.post(`cart/item/${itemKey}?cart_key=${cartKey}`, {
          quantity: quantity.toString(),
        });
      } catch (error) {
        console.error("Error updating cart amount:", error);
      }
    }, 500),
    [getCartKey, getCredentials]
  );

 
  const changeCartAmount = async (product, quantity) => {
    const itemKey = cart.find((item) => item.id === product.id)?.item_key;
    const updatedCart = cart.map((item) =>
      item.id === product.id ? { ...item, qty: quantity } : item
    );
    updateCartState(updatedCart);
    if (itemKey) {
      updateCart(itemKey, quantity);
    }
  };

  const removeFromCart = async (productId) => {

    const itemKey = cart.find((item) => item.id === productId)?.item_key;

    let url = `cart/item/${itemKey}?cart_key=${getCartKey()}`;

    if (itemKey) {
      const updatedCart = cart.filter((item) => item.id !== productId);
      const credentials = await getCredentials();
      // console.log(`credentials: ${credentials}`);

      if (credentials !== null) {
        CoCart.consumerKey = credentials[0];
        CoCart.consumerSecret = credentials[1];
        url = `cart/item/${itemKey}`;
      }
      try {
        const response = await CoCart.delete(url);
        if (response.status === 200 || response.status === 204) {
          updateCartState(updatedCart);
          return true;
        } else {
          throw new Error("Failed to remove item from cart");
        }
      } catch (error) {
        console.error("Error removing item from cart:", error);
      }
      refreshCart();
    }
  };

  const clearCart = () => {
    updateCartState([]);
  };


  return {
    addToCart,
    changeCartAmount,
    removeFromCart,
    refreshCart,
    getCartKeyUsedForCheckout,
    clearCart
  };
}
