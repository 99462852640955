"use client";

import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import EyeToggleButton from "../components/eye-toggle-button";
import { Fragment } from "react";
import { FlexRowCenter } from "components/flex-box";
import usePasswordVisible from "../use-password-visible";
import BazaarTextField from "components/BazaarTextField";
import CircularProgress from "@mui/material/CircularProgress";
import  {useCartActions}  from "hooks/useCartActions";
import { useRouter } from "next/navigation";
import { useUser } from "contexts/UserContext";
import { useEffect } from 'react'

const LoginPageView = ({ closeDialog }) => {
  const { visiblePassword, togglePasswordVisible } = usePasswordVisible();
  const [userMessage, setUserMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const { refreshCart } = useCartActions();
  const { push } = useRouter();

  useEffect(() => {
    if (user) {
      push('/profile');
    }
  }, [user, push]);

  const handleSuccessfulAuth = () => {
    push('/')
    setUserMessage("")
  }

  const handleRegisterClick = () => {
    push('/register')
  };



  const handlePwResetClick = () => {
    push('/reset-password')
  };


  const handleChangeAndClearMessage = (event) => {
    handleChange(event); 
    setUserMessage("");  
  };
  
  const initialValues = {
    email: "",
    password: "",
  };

  // LOGIN FORM FIELD VALIDATION SCHEMA
  const validationSchema = yup.object().shape({
    password:yup.string().required("Password is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values, { resetForm }) => {
        const formValues = values;
        resetForm();
        setUserMessage("");
        setLoading(true);

          try {
            const response = await fetch("/api/customer/login", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formValues),
            });

            const data = await response.json();

            // ON SUCCESS
            if (data.status === 200) {
              closeDialog?.();
              refreshCart();
              handleSuccessfulAuth();

            } else {
              setUserMessage(data.message || "Invalid Credentials");
            }
          } catch (error) {
            console.log("catch");
            console.error("Error submitting form:", error);
          } finally {
            setLoading(false);
          }
        
      },
    });

  return (
    <form onSubmit={handleSubmit} >
      <FlexRowCenter style={{ position: "relative", top: "-50px"}}>
        {loading && <CircularProgress color="primary" />}{" "}
      </FlexRowCenter>
      <BazaarTextField
        mb={1.5}
        fullWidth
        name="email"
        size="small"
        type="email"
        variant="outlined"
        onBlur={handleBlur}
        value={values.email}
        onChange={handleChangeAndClearMessage}
        label="Email"
        placeholder="example@mail.com"
        error={!!touched.email && !!errors.email}
        helperText={touched.email && errors.email}
      />

        <BazaarTextField
          mb={2}
          fullWidth
          size="small"
          name="password"
          label="Password"
          autoComplete="on"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChangeAndClearMessage}
          value={values.password}
          placeholder="*********"
          type={visiblePassword ? "text" : "password"}
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          InputProps={{
            endAdornment: (
              <EyeToggleButton
                show={visiblePassword}
                click={togglePasswordVisible}
              />
            ),
          }}
        />
    

      <Button
        fullWidth
        type="submit"
        color="primary"
        variant="contained"
        size="large"
      >
        Login
      </Button>

      <FlexRowCenter style={{ paddingTop: "1rem", marginBottom: "-1rem" }}>
        <p style={{ color: "red" }}>{userMessage}</p>
      </FlexRowCenter>

      <Fragment>
        <FlexRowCenter gap={1} my={3}>
        
    

         
            <Button
              onClick={handleRegisterClick}
              fontWeight={600}
              bordercolor="grey.900"
            >
              Don&apos;t have account?
            </Button>
          

          
            <Button
              onClick={handlePwResetClick}
              fontWeight={600}
              bordercolor="grey.900"
            >
              Forgot your password?
            </Button>
        

        
        

         
    
        
        </FlexRowCenter>

        {/* <FlexBox gap={1} py={2} borderRadius={1} justifyContent="center" bgcolor="grey.200">
        Forgot your password?
        <BoxLink title="Reset It" href="/reset-password" />
      </FlexBox> */}
      </Fragment>
    </form>
  );
};

export default LoginPageView;
