

"use client";

import { FlexRowCenter } from "components/flex-box";
import { useRouter } from "next/navigation";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from 'react';
import { useUser } from 'contexts/UserContext';
import { useCartActions } from "hooks/useCartActions";
import useCart from "hooks/useCart";

const deleteCookiesFromServer = async () => {
  console.log('Calling deleteCookiesFromServer');
  try {
    const response = await fetch('/api/customer/logout', {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();
    console.log('Server response:', data);

    return response.ok;
  } catch (error) {
    console.error('Error during logout:', error);
    return false;
  }
}

export default function LogoutPageView() {
  const { setUserID, setUser, userID } = useUser();
  const { push } = useRouter();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const {clearCart} = useCartActions();

  useEffect(() => {
    const logout = async () => {
      setIsLoggingOut(true); // Set logout flag to true
      const serverCallSuccess = await deleteCookiesFromServer();

      if (serverCallSuccess) {
        localStorage.removeItem('woo_cart_key');
        localStorage.removeItem('boh_cart_key');
        setUserID(null);
        setUser(null);
        console.log('Client-side logout actions complete');
        clearCart();
        push('/login');
      } else {
        console.error('Server-side logout failed');
        push('/profile');
      }

      setIsLoggingOut(false); // Reset logout flag
    };

    if (!isLoggingOut && (userID !== undefined && userID !== null)) {
      console.log('Calling logout, userID:', userID);
      logout();
    } else if (userID === null && !isLoggingOut) {
      console.log('No user ID found, redirecting to login');
      push('/login');
    }
  }, [userID, isLoggingOut, setUserID, setUser, push]);

  return (
    <FlexRowCenter>
      <CircularProgress color="primary" />
    </FlexRowCenter>
  );
}
